import { createSlice } from "@reduxjs/toolkit";
import { OptionBarConfig } from "../types";

const initialState: OptionBarConfig = {
    fontSize: '小四',
    fontFamily: '宋体',
    inlineBgColor: 'rgba(255,255,255,1)',
    inlineUnderLineColor: 'rgba(0,0,0,1)',
    inlineStrikeThroughColor: 'rgba(0,0,0,1)',
    inlineTextColor: 'rgba(0,0,0,1)',
    textShadingColor: '#ccc',
};

const optionBarSlice = createSlice({
    name: 'optionBar',
    initialState,
    // 相当于原来reducer中的case
    reducers: {
        // 后面文中我们将这类函数称之为 case
        setOptionBarConfig: (state, action) => {
            // console.log("setOptionBarConfig---->", state, action)
            //@ts-ignore
            state[action.payload.type] = action.payload.value;
        },
    }
});

// 官方推荐使用 ES6 解构和导出语法
// 提取action creator 对象与reducer函数
const { reducer, actions } = optionBarSlice;
// 提取并导出根据reducers命名的 action creator 函数
export const { setOptionBarConfig } = actions;
// 导出 reducer 函数
export default reducer;