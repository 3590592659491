import 'dayjs/locale/zh-cn';
import 'nprogress/nprogress.css';
import '@icon-park/react/styles/index.less';
import NProgress from 'nprogress';
import locale from 'antd/locale/zh_CN';
import React, { Suspense, lazy, ReactNode, useEffect } from 'react';
import { IconProvider, DEFAULT_ICON_CONFIGS } from '@icon-park/react'
import './App.css';
import type { FC } from 'react';
import { BrowserRouter, Routes, Route, Link, HashRouter, useNavigate } from 'react-router-dom';
import KeepAlive, { AliveScope, useAliveController } from 'react-activation';
import 'antd/dist/reset.css';
import './assets/style/com.scss';
import { Provider } from "react-redux";
import store from './redux/store';
import { ConfigProvider, message } from 'antd';
import { setNavigate } from './redux/navigate.slice';
const Login = lazy(() => import('./views/login/Login'));
const MainLayout = lazy(() => import('./layout/MainLayout'));
const UserManage = lazy(() => import('./views/system-manage/user-manage/UserManage'));
const DeptManage = lazy(() => import('./views/system-manage/dept-manage/DeptManage'));
const RoleManage = lazy(() => import('./views/system-manage/role-manage/RoleManage'));
const TopicManage = lazy(() => import('./views/data-manage/topic-manage/TopicManage'));
const TopicValueManage = lazy(() => import('./views/data-manage/topic-value-manage/TopicValueManage'));
const TopicTemplateManage = lazy(() => import('./views/data-manage/topic-template-manage/TopicTemplateManage'));
const ProtectTypeManage = lazy(() => import('./views/data-manage/protect-type-manage/ProtectTypeManage'));
const SpeciesBaseManage = lazy(() => import('./views/base-data-manage/species-base-manage/SpeciesBaseManage'));
const SpeciesTypeManage = lazy(() => import('./views/base-data-manage/species-type-manage/SpeciesTypeManage'));
const MapLayerManage = lazy(() => import('./views/map-manage/map-layer-manage/MapLayerManage'));
const GroupManage = lazy(() => import('./views/group-manage/group-manage/GroupManage'));
const GroupDataManage = lazy(() => import('./views/group-manage/group-data-manage/GroupDataManage'));
const GroupUserManage = lazy(() => import('./views/group-manage/group-user-manage/GroupUserManage'));
const LoginLog = lazy(() => import('./views/system-monitor/login-log/LoginLog'));
const OptionLog = lazy(() => import('./views/system-monitor/option-log/OptionLog'));
const AbnormalAlarm = lazy(() => import('./views/system-monitor/abnormal-alarm/AbnormalAlarm'));
const FeedBack = lazy(() => import('./views/system-monitor/feed-back/FeedBack'));
const DataKanban = lazy(() => import('./views/system-monitor/data-kanban/DataKanban'));
const PanningBasisManage = lazy(() => import('./views/data-manage/panning-basis-manage/PanningBasisManage'));
const PaidPackageManage = lazy(() => import('./views/website-manage/paid-package-manage/PaidPackageManage'))

const App: FC = () => {

  useEffect(() => {
    message.config({
      top: 21,
    });
    return () => {

    }
  })

  const LoadingProgress: FC = () => {
    useEffect(() => {
      NProgress.start();
      return () => {
        NProgress.done();
      }
    })
    return (
      <div></div>
    )
  };

  const lazyLoadRouteEl = (chilren: ReactNode) => {
    return (<Suspense
      fallback={
        <LoadingProgress />
      }>
      {chilren}
    </Suspense>)
  }

  const IconConfig = { ...DEFAULT_ICON_CONFIGS, prefix: 'icon' }

  return (
    <div className="App app-outer-container">
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#337DE5',
            },
          }}
          locale={locale}
        >
          <IconProvider value={IconConfig}>
            <BrowserRouter>
              <AliveScope>
                <Routes>
                  <Route path='/' element={(<MainLayout />)}>
                    <Route path="user-manage" element={lazyLoadRouteEl(<UserManage />)} />
                    <Route path="dept-manage" element={lazyLoadRouteEl(<DeptManage />)} />
                    <Route path="role-manage" element={lazyLoadRouteEl(<RoleManage />)} />
                    <Route path="topic-manage" element={(
                      <KeepAlive autoFreeze={false} cacheKey='TopicManage'>
                        {lazyLoadRouteEl(<TopicManage />)}
                      </KeepAlive>
                    )}
                    />
                    <Route path="topic-value-manage" element={(
                      <KeepAlive autoFreeze={false} cacheKey='TopicValueManage'>
                        {lazyLoadRouteEl(<TopicValueManage />)}
                      </KeepAlive>
                    )} />
                    <Route path="project-template-manage" element={(
                      <KeepAlive autoFreeze={false} cacheKey='TopicTemplateManage'>
                        {lazyLoadRouteEl(<TopicTemplateManage />)}
                      </KeepAlive>
                    )} />
                    <Route path="protect-type-manage" element={lazyLoadRouteEl(<ProtectTypeManage />)} />
                    <Route path="species-base-manage" element={lazyLoadRouteEl(<SpeciesBaseManage />)} />
                    <Route path="species-type-manage" element={lazyLoadRouteEl(<SpeciesTypeManage />)} />
                    <Route path="map-layer-manage" element={(
                      <KeepAlive autoFreeze={false} cacheKey='MapLayerManage'>
                        {lazyLoadRouteEl(<MapLayerManage />)}
                      </KeepAlive>
                    )} />
                    <Route path="group-manage" element={lazyLoadRouteEl(<GroupManage />)} />
                    <Route path="group-data-manage" element={lazyLoadRouteEl(<GroupDataManage />)} />
                    <Route path="group-user-manage" element={lazyLoadRouteEl(<GroupUserManage />)} />
                    <Route path="login-log" element={lazyLoadRouteEl(<LoginLog />)} />
                    <Route path="option-log" element={lazyLoadRouteEl(<OptionLog />)} />
                    <Route path="abnormal-alarm" element={lazyLoadRouteEl(<AbnormalAlarm />)} />
                    <Route path="feed-back" element={lazyLoadRouteEl(<FeedBack />)} />
                    <Route path="dashboard" element={lazyLoadRouteEl(<DataKanban />)} />
                    <Route path="panning-basis-manage" element={lazyLoadRouteEl(<PanningBasisManage/>)}/>
                    <Route path='paid-package-manage' element={lazyLoadRouteEl(<PaidPackageManage/>)}/>
                  </Route>
                  <Route path="/login" element={lazyLoadRouteEl(<Login />)} />
                </Routes>
              </AliveScope>
            </BrowserRouter>
          </IconProvider>
        </ConfigProvider>
      </Provider>
    </div>
  );
}

export default App;


// import React, { useRef } from 'react';
// import { Canvas, useFrame } from '@react-three/fiber';
// import { Text } from '@react-three/drei';

// const MovingText: React.FC = () => {
//   const textRef = useRef<any>();

//   useFrame(() => {
//     if (textRef.current) {
//       // 检查文本的位置，如果还没有接近观察点（假设观察点为z=0），则继续移动
//       if (textRef.current.position.z < 0) {
//         textRef.current.position.z += 0.1; // 调整这个值可以改变移动速度
//       }
//     }
//   });

//   return (
//     <Text
//       ref={textRef}
//       color="orange" // 可以直接指定颜色
//       fontSize={1.0} // 字体大小
//       position={[0, 0, -5]} // 初始位置在远处
//       anchorX="center" // 水平锚点
//       anchorY="middle" // 垂直锚点
//     >
//       Hello World
//     </Text>
//   );
// };

// const Scene: React.FC = () => {
//   return (
//     <Canvas>
//       {/* <ambientLight intensity={0.5} /> */}
//       <MovingText />
//     </Canvas>
//   );
// };

// export default Scene;



// import React, { useRef } from 'react';
// import { Canvas, useFrame } from '@react-three/fiber';
// import { Text } from '@react-three/drei';
// import * as THREE from 'three';

// const positiveWords = ['喜', '乐', '爱', '和', '平', '福', '希望'];

// const Room: React.FC = () => {
//   return (
//     <>
//       <mesh position={[0, 0, -5]}>
//         <boxGeometry args={[10, 10, 0.1]} />
//         <meshStandardMaterial color="lightblue" side={THREE.DoubleSide} />
//       </mesh>
//       <mesh position={[0, 0, 5]} rotation={[0, Math.PI, 0]}>
//         <boxGeometry args={[10, 10, 0.1]} />
//         <meshStandardMaterial color="lightblue" side={THREE.DoubleSide} />
//       </mesh>
//       <mesh position={[-5, 0, 0]} rotation={[0, Math.PI / 2, 0]}>
//         <boxGeometry args={[10, 10, 0.1]} />
//         <meshStandardMaterial color="lightblue" side={THREE.DoubleSide} />
//       </mesh>
//       <mesh position={[5, 0, 0]} rotation={[0, -Math.PI / 2, 0]}>
//         <boxGeometry args={[10, 10, 0.1]} />
//         <meshStandardMaterial color="lightblue" side={THREE.DoubleSide} />
//       </mesh>
//       <mesh position={[0, -5, 0]} rotation={[Math.PI / 2, 0, 0]}>
//         <boxGeometry args={[10, 10, 0.1]} />
//         <meshStandardMaterial color="lightblue" side={THREE.DoubleSide} />
//       </mesh>
//     </>
//   );
// };

// const MovingText: React.FC = () => {
//   const textMeshes = useRef<(THREE.Mesh | undefined)[]>([]);

//   useFrame(() => {
//     textMeshes.current.forEach(mesh => {
//       if (mesh && mesh.position.z < 5) {
//         mesh.position.z += 0.05; // Adjust this value to change the speed
//       }
//     });
//   });

//   return (
//     <>
//       {positiveWords.map((word, index) => (
//         <Text
//           key={index}
//           ref={el => {
//             if (el) textMeshes.current[index] = el;
//           }}
//           color="black"
//           fontSize={0.5}
//           position={[Math.random() * 4 - 2, Math.random() * 4 - 2, -5]}
//           // text={}
//           >
//             {word}
//         </Text>
//       ))}
//     </>
//   );
// };

// const Scene: React.FC = () => {
//   return (
//     <Canvas camera={{ position: [0, 0, 10], fov: 75 }}>
//       <ambientLight intensity={0.5} />
//       <Room />
//       <MovingText />
//     </Canvas>
//   );
// };

// export default Scene;
